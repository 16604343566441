import React, { useEffect, useState } from 'react';

// Declare global adsbygoogle type for TypeScript
declare global {
  interface Window {
    adsbygoogle: any[];
  }
}

interface AdUnitProps {
  className?: string;
}

const AdUnit: React.FC<AdUnitProps> = ({ className = '' }) => {
  const [adLoaded, setAdLoaded] = useState(false);
  const [adError, setAdError] = useState<string | null>(null);

  useEffect(() => {
    try {
      // Check if adsbygoogle is available
      if (window.adsbygoogle) {
        // Initialize the ad
        (window.adsbygoogle = window.adsbygoogle || []).push({});
        console.log('AdSense push called successfully');
        
        // Set a timeout to check if ad loaded
        setTimeout(() => {
          const adElement = document.querySelector('.adsbygoogle');
          const hasContent = adElement && adElement.innerHTML.trim().length > 0;
          setAdLoaded(hasContent || false);
          
          if (!hasContent) {
            console.warn('AdSense ad might not be displaying content');
          }
        }, 2000);
      } else {
        console.warn('AdSense not available - might be blocked by an ad blocker');
        setAdError('AdSense not available');
      }
    } catch (error) {
      console.error('AdSense error:', error);
      setAdError(error instanceof Error ? error.message : 'Unknown error');
    }
  }, []);

  return (
    <div className={`ad-container ${className}`}>
      <ins
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-3486600369587030"
        data-ad-slot="4279165514"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
      {!adLoaded && adError && process.env.NODE_ENV === 'development' && (
        <div className="ad-fallback" style={{ 
          padding: '20px', 
          textAlign: 'center',
          border: '1px dashed #ccc',
          backgroundColor: '#f8f8f8',
          marginTop: '10px',
          marginBottom: '10px'
        }}>
          <p><strong>Ad space</strong> - {adError}</p>
          <p style={{ fontSize: '12px' }}>This message only appears in development mode</p>
        </div>
      )}
    </div>
  );
};

export default AdUnit; 
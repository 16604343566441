import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/ToolPage.css';
import buyMeCoffeeButton from '../assets/images/bmc-button.png';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3 className="footer-title">Info-Mask</h3>
          <div className="footer-links">
            <Link to="/" className="footer-link">Home</Link>
            <Link to="/tool" className="footer-link">Tool</Link>
            <Link to="/about" className="footer-link">About</Link>
            <Link to="/blog" className="footer-link">Blog</Link>
            <Link to="/docs" className="footer-link">Documentation</Link>
            <Link to="/faq" className="footer-link">FAQ</Link>
            <p className="footer-description">
              Professional data masking tool for SOC analysts and developers.
            </p>
          </div>
        </div>

        <div className="footer-section">
          <h3 className="footer-title">Legal</h3>
          <div className="footer-links">
            <Link to="/privacy-policy" className="footer-link">Privacy Policy</Link>
            <Link to="/terms" className="footer-link">Terms of Use</Link>
          </div>
        </div>

        <div className="footer-section">
          <h3 className="footer-title">Contact Us</h3>
          <div className="footer-links">
            <Link to="/contact" className="footer-link">Contact Us</Link>
            <h3 className="footer-title">Support Us</h3>
            <a href="https://www.buymeacoffee.com/infomask" target="_blank" rel="noopener noreferrer">
              <img 
                src={buyMeCoffeeButton}
                alt="Buy Me A Coffee" 
                style={{ height: '60px', width: '217px' }}
              />
            </a>
          </div>
        </div>

        <div className="footer-section">
          <h3 className="footer-title">Social</h3>
          <div className="footer-links">
            <a href="https://www.youtube.com/@info-mask" target="_blank" rel="noopener noreferrer" className="footer-link">
              <svg className="youtube-icon" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"/>
              </svg>
              YouTube Channel
            </a>
          </div>
        </div>
      </div>
      
      <div className="footer-bottom">
        <p className="footer-copyright">© 2024 Info-Mask. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import '../styles/Pages.css';

const Terms: React.FC = () => {
  return (
    <div className="page-container">
      <Link to="/" className="back-to-home">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <path d="M19 12H5M12 19l-7-7 7-7"/>
        </svg>
        Back to Home
      </Link>
      <main className="content-container">
        <h1>Terms of Use</h1>
        
        <section className="content-section">
          <h2>Last Updated: January 8, 2025</h2>
          <p>
            Please read these Terms of Use ("Terms") carefully before using Info-Mask. By accessing or using Info-Mask,
            you agree to be bound by these Terms. If you disagree with any part of these Terms, you may not access or
            use our service.
          </p>
        </section>

        <section className="content-section">
          <h2>Intellectual Property Rights</h2>
          <p>
            Info-Mask and its original content, features, and functionality are owned by Info-Mask and are protected by
            international copyright, trademark, patent, trade secret, and other intellectual property or proprietary
            rights laws.
          </p>
        </section>

        <section className="content-section">
          <h2>Use License</h2>
          <p>
            Subject to these Terms, Info-Mask grants you a limited, non-exclusive, non-transferable, non-sublicensable
            license to access and use our service for your personal or internal business use.
          </p>
          <p>
            This license is subject to the following restrictions:
          </p>
          <ul>
            <li>You may not modify, decompile, or reverse engineer any software contained in Info-Mask</li>
            <li>You may not use the service for any illegal or unauthorized purpose</li>
            <li>You may not attempt to gain unauthorized access to any portion of the service</li>
            <li>You may not interfere with or disrupt the service or servers</li>
            <li>You may not circumvent, disable, or otherwise interfere with security-related features</li>
          </ul>
        </section>

        <section className="content-section">
          <h2>User Responsibilities</h2>
          <p>
            When using Info-Mask, you agree to:
          </p>
          <ul>
            <li>Use the service in compliance with all applicable laws and regulations</li>
            <li>Be responsible for all activities that occur under your use</li>
            <li>Ensure that your use of the service does not violate any third-party rights</li>
            <li>Not use the service in any way that could damage, disable, or impair the service</li>
            <li>Not attempt to access data not intended for you</li>
          </ul>
        </section>

        <section className="content-section">
          <h2>Data Usage and Privacy</h2>
          <p>
            Your use of Info-Mask is also governed by our Privacy Policy. By using Info-Mask, you acknowledge that:
          </p>
          <ul>
            <li>All data processing occurs locally in your browser</li>
            <li>You are responsible for the data you process through our service</li>
            <li>You will not use the service to process data in violation of any laws or regulations</li>
            <li>You will comply with all applicable data protection and privacy laws</li>
          </ul>
        </section>

        <section className="content-section">
          <h2>Disclaimer of Warranties</h2>
          <p>
            Info-Mask is provided "as is" and "as available" without any warranties of any kind, either express or
            implied, including but not limited to:
          </p>
          <ul>
            <li>The service will be uninterrupted, timely, secure, or error-free</li>
            <li>The results of using the service will be accurate or reliable</li>
            <li>Any errors in the service will be corrected</li>
          </ul>
        </section>

        <section className="content-section">
          <h2>Limitation of Liability</h2>
          <p>
            To the fullest extent permitted by law, Info-Mask shall not be liable for any indirect, incidental,
            special, consequential, or punitive damages, including without limitation:
          </p>
          <ul>
            <li>Loss of profits, data, use, goodwill, or other intangible losses</li>
            <li>Any damages resulting from your access to or use of our service</li>
            <li>Any damages resulting from any errors in the masking or replacement of data</li>
            <li>Any damages resulting from interruption or cessation of the service</li>
          </ul>
        </section>

        <section className="content-section">
          <h2>Indemnification</h2>
          <p>
            You agree to defend, indemnify, and hold harmless Info-Mask and its licensors from and against any
            claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees arising out of or
            relating to your use of the service or any violation of these Terms.
          </p>
        </section>

        <section className="content-section">
          <h2>Modifications to Service</h2>
          <p>
            We reserve the right to:
          </p>
          <ul>
            <li>Modify or discontinue any part of our service without notice</li>
            <li>Update these Terms at any time</li>
            <li>Change the fees for our service (with advance notice)</li>
          </ul>
          <p>
            Your continued use of Info-Mask following the posting of revised Terms means that you accept and agree
            to the changes.
          </p>
        </section>

        <section className="content-section">
          <h2>Governing Law</h2>
          <p>
            These Terms shall be governed by and construed in accordance with the laws of Israel, without regard
            to its conflict of law provisions.
          </p>
        </section>

        <section className="content-section">
          <h2>Contact Information</h2>
          <p>Contact us at: support@info-mask.com</p>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default Terms; 
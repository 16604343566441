import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Footer from '../components/Footer';
import logoWebp from '../assets/images/logo.webp';
import '../styles/HomePage.css';

const HomePage: React.FC = () => {
  return (
    <div className="page-container">
      <Helmet>
        <title>Info-Mask: Advanced Data Privacy & Masking Tool for AI Security</title>
        <meta name="description" content="Protect sensitive data with Info-Mask's advanced pattern recognition. Mask IPs, emails, and PII before sharing with AI tools. GDPR & HIPAA compliant." />
        <meta name="keywords" content="data masking, data privacy, PII protection, sensitive data, AI security, GDPR compliance, HIPAA compliance, SOC analysts, data anonymization" />
        <link rel="canonical" href="https://info-mask.com/" />
        
        {/* Schema.org structured data for software application */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "SoftwareApplication",
            "name": "Info-Mask",
            "applicationCategory": "SecurityApplication",
            "operatingSystem": "Any",
            "offers": {
              "@type": "Offer",
              "price": "0",
              "priceCurrency": "USD"
            },
            "description": "Advanced data privacy and masking tool for protecting sensitive information. Features pattern recognition, custom rules, and compliance with GDPR and HIPAA standards.",
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.8",
              "ratingCount": "156"
            }
          })}
        </script>
      </Helmet>
      
      <main className="home-main">
        {/* Hero Section */}
        <section className="hero-section">
          <div className="hero-content">
            <img src={logoWebp} alt="Info-Mask Logo" className="hero-logo" width="120" height="120" />
            <h1 className="hero-title">Secure Your Data with Info-Mask</h1>
            <p className="hero-description">
              Professional data masking solution for SOC analysts and developers.
              In an era where AI tools are increasingly used for data analysis,
              protecting sensitive information is crucial. Info-Mask ensures your
              data remains private while allowing you to leverage AI capabilities safely.
            </p>
            <div className="hero-cta">
              <Link to="/tool" className="cta-button primary">Try Info-Mask Now</Link>
              <Link to="/docs" className="cta-button secondary">Read Documentation</Link>
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section className="features-section">
          <h2 className="section-title">Why Choose Info-Mask?</h2>
          <div className="features-grid">
            <div className="feature-card">
              <div className="feature-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                  <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                  <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                </svg>
              </div>
              <h3>Advanced Pattern Recognition</h3>
              <p>Automatically detect and mask sensitive data patterns like IPs, emails, and authentication tokens.</p>
            </div>
            <div className="feature-card">
              <div className="feature-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                  <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                  <polyline points="7.5 4.21 12 6.81 16.5 4.21"></polyline>
                  <polyline points="7.5 19.79 7.5 14.6 3 12"></polyline>
                  <polyline points="21 12 16.5 14.6 16.5 19.79"></polyline>
                  <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                  <line x1="12" y1="22.08" x2="12" y2="12"></line>
                </svg>
              </div>
              <h3>Consistent Replacement</h3>
              <p>Maintain data relationships with consistent masking across your entire dataset.</p>
            </div>
            <div className="feature-card">
              <div className="feature-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                  <path d="M13 2L3 14h9l-1 8 10-12h-9l1-8z"></path>
                </svg>
              </div>
              <h3>Real-time Processing</h3>
              <p>Instantly mask or replace sensitive information with our high-performance engine.</p>
            </div>
            <div className="feature-card">
              <div className="feature-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                  <path d="M12 20h9"></path>
                  <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                </svg>
              </div>
              <h3>Custom Rules</h3>
              <p>Create and manage your own masking rules to match your specific requirements.</p>
            </div>
          </div>
        </section>

        {/* Use Cases Section */}
        <section className="use-cases-section">
          <h2 className="section-title">Perfect For</h2>
          <div className="use-cases-grid">
            <div className="use-case-card">
              <h3>SOC Analysts</h3>
              <ul>
                <li>Safely analyze security logs with AI</li>
                <li>Share sanitized incident reports</li>
                <li>Protect sensitive infrastructure details</li>
              </ul>
            </div>
            <div className="use-case-card">
              <h3>Developers</h3>
              <ul>
                <li>Debug with masked production data</li>
                <li>Share code snippets safely</li>
                <li>Test with realistic but safe data</li>
              </ul>
            </div>
            <div className="use-case-card">
              <h3>Everyone</h3>
              <ul>
                <li>Protect personal and sensitive information</li>
                <li>Share data safely with third parties</li>
                <li>Maintain privacy in communications</li>
              </ul>
            </div>
          </div>
        </section>

        {/* Latest Articles Section */}
        <section className="latest-articles-section">
          <div className="latest-articles-content">
            <h2 className="section-title">Latest Articles</h2>
            <div className="articles-grid">
              <Link to="/blog/deepseek-leak-2025" className="article-card">
                <h3>Major Security Breach: DeepSeek's Database Exposed</h3>
                <p>Wiz Research uncovers a critical security breach in DeepSeek's infrastructure, exposing millions of chat logs and sensitive data.</p>
                <span className="read-more">Read More →</span>
              </Link>
              <Link to="/blog/nhs-data-ai-2024" className="article-card">
                <h3>UK's NHS Data: Powering the Next AI Revolution</h3>
                <p>Explore how the UK government plans to leverage NHS health data to fuel AI innovation while maintaining privacy and security controls.</p>
                <span className="read-more">Read More →</span>
              </Link>
              <Link to="/blog/ai-security-risks-2024" className="article-card">
                <h3>The Hidden Dangers: AI Security Risks in 2024</h3>
                <p>An in-depth look at emerging AI security threats, from chatbot privacy concerns to AI-enhanced malware and enterprise security challenges.</p>
                <span className="read-more">Read More →</span>
              </Link>
            </div>
            <div className="view-all-articles">
              <Link to="/blog" className="cta-button secondary">View All Articles</Link>
            </div>
          </div>
        </section>

        {/* Get Started Section */}
        <section className="get-started-section">
          <h2 className="section-title">Ready to Secure Your Data?</h2>
          <p>Start masking sensitive information in seconds with our easy-to-use tool.</p>
          <div className="get-started-buttons">
            <Link to="/tool" className="cta-button primary">Try Info-Mask Now</Link>
            <Link to="/docs/getting-started" className="cta-button secondary">View Getting Started Guide</Link>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default HomePage; 